import { LegacyCard, LegacyStack, Modal, Text } from "@shopify/polaris";
import React from "react";

import { ModalTitle } from "../../../../../../libs/styles";
import { SUB_MODAL_DEMO_TITLE, SUB_MODAL_TITLE } from "../../../constants";
import { SubscriptionCreationEnum } from "../../../types";

interface ICreateSubscriptionModal {
  isModalOpen: boolean;
  setModalOpen: (value: React.SetStateAction<boolean>) => void;
  modalType: SubscriptionCreationEnum;
  isDemoSubscriptionProgramLoading: boolean;
  handleCreatingDemoSubscriptionProgram: () => Promise<void>;
  handleCreatingNewSubscriptionProgram: () => void;
}

const CreateSubscriptionModal = ({
  isModalOpen,
  setModalOpen,
  modalType,
  isDemoSubscriptionProgramLoading,
  handleCreatingDemoSubscriptionProgram,
  handleCreatingNewSubscriptionProgram,
}: ICreateSubscriptionModal) => {
  return (
    <Modal
      open={isModalOpen}
      onClose={() => setModalOpen(false)}
      title={
        <ModalTitle>
          {modalType === SubscriptionCreationEnum.DEMO ? SUB_MODAL_DEMO_TITLE : SUB_MODAL_TITLE}
        </ModalTitle>
      }
      primaryAction={{
        content: "Confirm",
        loading: isDemoSubscriptionProgramLoading,
        onAction() {
          if (modalType === SubscriptionCreationEnum.DEMO) {
            handleCreatingDemoSubscriptionProgram().then(() => {
              setModalOpen(false);
            });
          } else {
            handleCreatingNewSubscriptionProgram();
          }
        },
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => setModalOpen(false),
        },
      ]}
    >
      {modalType === SubscriptionCreationEnum.DEMO ? (
        <React.Fragment>
          <Modal.Section>
            Smartrr will create the below demo subscription program. You&apos;ll be able to manage this
            subscription program later by visiting Subscription Programs in the left-hand navigation.
          </Modal.Section>

          <Modal.Section>
            <LegacyCard sectioned>
              <LegacyStack vertical>
                <Text variant="headingLg" as="p">
                  Subscribe & Save 10%
                </Text>
                <div>Subscribe & Save 10%</div>
              </LegacyStack>
            </LegacyCard>
          </Modal.Section>
        </React.Fragment>
      ) : (
        <Modal.Section>
          You&apos;ll be redirected to our <strong>Subscription Programs</strong> tab to create a new program from
          scratch. Once complete, you&apos;ll be prompted to return here to continue with required setup.
        </Modal.Section>
      )}
    </Modal>
  );
};

export default CreateSubscriptionModal;
