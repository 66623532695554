import { Icon, LegacyStack } from "@shopify/polaris";
import { ExternalSmallMinor, InfoMinor } from "@shopify/polaris-icons";
import React from "react";

import { DocIconWrapper, DocsLink, DocsLinkContainer, DocsLinkWrapper } from "../../../../../libs/styles";

const HelpDocsLink = () => {
  return (
    <DocsLinkContainer>
      <LegacyStack distribution="center" spacing="tight">
        <Icon source={InfoMinor} color="highlight" />
        <DocsLinkWrapper>
          Still need help?&nbsp;
          <DocsLink href="https://help.smartrr.com/docs/" rel="noreferrer" target="_blank">
            Read our help documentation.
            <DocIconWrapper>
              <Icon source={ExternalSmallMinor} color="highlight" />
            </DocIconWrapper>
          </DocsLink>
        </DocsLinkWrapper>
      </LegacyStack>
    </DocsLinkContainer>
  );
};

export default HelpDocsLink;
