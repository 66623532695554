export { sellingPlanInput } from "./sellingPlanInput";

export const THEME_CONFIRMATION_TITLE = "Theme confirmation";
export const THEME_CONFIRMATION_CONFIRMATION_LABEL =
  "I'm planning on using Smartrr's subscription widget via Shopify's theme editor.";
export const SNIPPETS_CARD_TITLE = "1. Install Smartrr snippets";
export const SNIPPETS_CARD_BANNER_TITLE = "We recommend first duplicating your live theme.";

export const SUB_PROGRAM_URL = "/admin/configure/plans";

export const SUBSCRIPTION_SETUP_TAB_LABEL = "Subscription setup";
export const ONSITE_SETUP_TAB_LABEL = "On-site setup";
export const BADGE_BASE_TEXT = "/2 complete";
