import { ISetup } from "@smartrr/shared/entities/Organization";
import { useEffect, useState } from "react";

const useSetCounts = (setup: ISetup) => {
  const [onSiteSetupCount, setOnSiteSetupCount] = useState<number>(0);
  const [subscriptionSetupCount, setSubscriptionSetupCount] = useState<number>(0);
  useEffect(() => {
    if (setup.isSetupFinished) {
      setSubscriptionSetupCount(2);
      setOnSiteSetupCount(2);
    }

    if (
      setup.subscriptionSetup?.product &&
      setup.subscriptionSetup.product.completed &&
      setup.subscriptionSetup?.sellingPlan &&
      setup.subscriptionSetup.sellingPlan.completed
    ) {
      setSubscriptionSetupCount(2);
    }
    if (
      !setup.subscriptionSetup?.product?.completed &&
      setup.subscriptionSetup?.sellingPlan &&
      setup.subscriptionSetup.sellingPlan.completed
    ) {
      setSubscriptionSetupCount(1);
    }

    if (
      setup.onSiteSetup?.smartrrSnippets &&
      setup.onSiteSetup?.smartrrSnippets?.completed &&
      setup.onSiteSetup?.smartrrWidget &&
      setup.onSiteSetup?.smartrrWidget.completed
    ) {
      setOnSiteSetupCount(2);
    }

    if (
      !setup.onSiteSetup?.smartrrWidget &&
      setup.onSiteSetup?.smartrrSnippets &&
      setup.onSiteSetup?.smartrrSnippets?.completed
    ) {
      setOnSiteSetupCount(1);
    }
  }, [setup]);
  return { onSiteSetupCount, subscriptionSetupCount, setOnSiteSetupCount };
};

export default useSetCounts;
