import { useSmartrrVendorSelector } from "../../../../../_state/typedVendorReduxHooks";
import useSetCounts from "../useSetCounts";

const useAdminSetupRouteLocalData = () => {
  const { setup } = useSmartrrVendorSelector(state => state.vendorOrganizations);
  const { purchasables, isLoading: isPurchasablesLoading } = useSmartrrVendorSelector(
    state => state.purchasables
  );
  const { onSiteSetupCount, subscriptionSetupCount, setOnSiteSetupCount } = useSetCounts(setup);

  return {
    setup,
    purchasables,
    isPurchasablesLoading,
    onSiteSetupCount,
    subscriptionSetupCount,
    setOnSiteSetupCount,
  };
};

export default useAdminSetupRouteLocalData;
