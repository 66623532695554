import { Button, Icon, LegacyCard, LegacyStack, Link, Text } from "@shopify/polaris";
import { CircleTickMajor } from "@shopify/polaris-icons";
import { ISODateString } from "@smartrr/shared/entities/ISODateString";
import { ISetup } from "@smartrr/shared/entities/Organization";
import React, { useState } from "react";

import { SUB_PROGRAM_URL } from "../../../../../../libs/constants";
import { SetupIconContainer, SetupIconWrapper } from "../../../../../../libs/styles";
import { SetUpAProgramCardTitle } from "../../../constants";
import useSubscriptionSetupRemoteData from "../../../hooks/useSubscriptionSetupRemoteData";
import { SubscriptionCreationEnum } from "../../../types";
import CreateSubscriptionModal from "../../Modals/CreateSubscriptionModal";

interface ISubscriptionProgramCard {
  setup: ISetup;
}

const SubscriptionProgramCard = ({ setup }: ISubscriptionProgramCard) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<SubscriptionCreationEnum>(SubscriptionCreationEnum.DEMO);
  const {
    isDemoSubscriptionProgramLoading,
    handleCreatingNewSubscriptionProgram,
    handleCreatingDemoSubscriptionProgram,
  } = useSubscriptionSetupRemoteData(setModalOpen);
  return (
    <LegacyCard title={SetUpAProgramCardTitle}>
      <LegacyCard.Section>
        <Text variant="bodyMd" as="p">
          Subscription programs control which products are available for subscription purchase. Select if you
          would Smartrr to create a demo program or if you would like to create your own program from scratch
        </Text>{" "}
        &nbsp;
        <LegacyStack vertical>
          <LegacyStack>
            <Button
              onClick={() => {
                setModalOpen(true);
                setModalType(SubscriptionCreationEnum.DEMO);
              }}
            >
              Use a demo program
            </Button>
            {!!setup.subscriptionSetup?.sellingPlan?.demo &&
              !!setup.subscriptionSetup?.sellingPlan?.completed && (
                <SetupIconContainer>
                  <SetupIconWrapper>
                    <Icon source={CircleTickMajor} color="base" />
                  </SetupIconWrapper>
                  <Text
                    variant="bodyMd"
                    as="span"
                    color="subdued"
                  >{` You created a demo program on ${ISODateString.fromString(
                    setup.subscriptionSetup.sellingPlan.date
                  ).toLocaleString()}`}</Text>
                </SetupIconContainer>
              )}
          </LegacyStack>
          <LegacyStack>
            <Button
              onClick={() => {
                setModalOpen(true);
                setModalType(SubscriptionCreationEnum.NEW);
              }}
              primary
            >
              Create my own program
            </Button>
            {!setup.subscriptionSetup?.sellingPlan?.demo && !!setup.subscriptionSetup?.sellingPlan?.completed && (
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                <SetupIconWrapper>
                  <Icon source={CircleTickMajor} color="base" />
                </SetupIconWrapper>
                <Text variant="bodyMd" as="span">
                  <Link external url={SUB_PROGRAM_URL}>
                    Manage subscription programs
                  </Link>
                </Text>
              </div>
            )}
          </LegacyStack>
        </LegacyStack>
      </LegacyCard.Section>
      <CreateSubscriptionModal
        modalType={modalType}
        isModalOpen={isModalOpen}
        isDemoSubscriptionProgramLoading={isDemoSubscriptionProgramLoading}
        setModalOpen={setModalOpen}
        handleCreatingDemoSubscriptionProgram={handleCreatingDemoSubscriptionProgram}
        handleCreatingNewSubscriptionProgram={handleCreatingNewSubscriptionProgram}
      />
    </LegacyCard>
  );
};

export default SubscriptionProgramCard;
