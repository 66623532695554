import { Banner, LegacyStack, Text } from "@shopify/polaris";
import React from "react";

import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import SubscriptionProductsCard from "./libs/components/Cards/SubscriptionProductsCard";
import SubscriptionProgramCard from "./libs/components/Cards/SubscriptionProgramCard";
import HelpDocsLink from "./libs/components/HelpDocsLink";
import Optimizations from "./libs/components/Optimizations";
import { SUBSCRIPTION_SET_UP_TITLE } from "./libs/constants";
import useFeatureAvailability from "./libs/hooks/useFeatureAvailability";
import { useSubscriptionEligibility } from "./libs/hooks/useSubscriptionEligibility";
import { TabHeader } from "../../libs/styles";

// node ./node_modules/intermock/build/src/cli/index.js --files ./example-file.ts --interfaces "Admin"
export interface ISubscriptionSetup {
  linkedProductIds: string[];
  linkedVariantIds: string[];
  isProductsPageLoading: boolean;
  isVariantsPageLoading: boolean;
  setIsProductsPageLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsVariantsPageLoading: React.Dispatch<React.SetStateAction<boolean>>;
  loadProductsAndVariantsFromShopify: (selectedSellingPlanId: string) => Promise<void>;
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
}

export const SubscriptionSetup = ({
  isProductsPageLoading,
  isVariantsPageLoading,
  linkedProductIds,
  linkedVariantIds,
  loadProductsAndVariantsFromShopify,
  setIsProductsPageLoading,
  setIsVariantsPageLoading,
  setSelectedTab,
}: ISubscriptionSetup) => {
  const { setup } = useSmartrrVendorSelector(state => state.vendorOrganizations);
  const { isLoyaltyAvailable, isPasswordlessAvailable, isRetentionAvailable, isIntegrationsAvailable } =
    useFeatureAvailability();
  const { eligibleForSubscriptions } = useSubscriptionEligibility();

  return (
    <LegacyStack vertical>
      <TabHeader>
        <Text variant="headingLg" as="h2" fontWeight="semibold">
          {SUBSCRIPTION_SET_UP_TITLE}{" "}
        </Text>
      </TabHeader>
      {!eligibleForSubscriptions && (
        <Banner title="Warning" status="warning">
          <Text variant="bodyMd" as="p" fontWeight="regular">
            Smartrr has detected that your shop is currently ineligible to create subscriptions through Shopify.
            Please reference Shopify&apos;s full list of{" "}
            <a
              data-testid="subscription-set-up-ineligible-link"
              rel="noreferrer"
              href="https://help.shopify.com/en/manual/products/subscriptions/setup#eligibility-requirements"
              style={{ textDecoration: "underline" }}
            >
              subscription eligibility requirements.
            </a>
          </Text>
        </Banner>
      )}
      <SubscriptionProgramCard setup={setup} />
      <SubscriptionProductsCard
        linkedProductIds={linkedProductIds}
        isProductsPageLoading={isProductsPageLoading}
        isVariantsPageLoading={isVariantsPageLoading}
        linkedVariantIds={linkedVariantIds}
        loadProductsAndVariantsFromShopify={loadProductsAndVariantsFromShopify}
        setSelectedTab={setSelectedTab}
      />
      <HelpDocsLink />
      <Optimizations
        isLoyaltyAvailable={isLoyaltyAvailable}
        isPasswordlessAvailable={isPasswordlessAvailable}
        isRetentionAvailable={isRetentionAvailable}
        isIntegrationsAvailable={isIntegrationsAvailable}
      />
    </LegacyStack>
  );
};
