import { ISODateString } from "@smartrr/shared/entities/ISODateString";

export interface ISetupFieldsForUpdate {
  selectedSellingPlanId?: string;
  hasProductSelected?: boolean;
  snippetsThemeId?: string;
  renderingThemeId?: string;
  isSetupFinished?: boolean;
  hasOrgLoggedIn?: boolean;
  subscriptionSetup?: {
    sellingPlan?: {
      date: ISODateString;
      completed: boolean;
      demo: boolean;
    };
    product?: {
      date: ISODateString;
      completed: boolean;
      demo: boolean;
    };
  };
  onSiteSetup?: {
    smartrrSnippets?: {
      date: ISODateString;
      completed: boolean;
      themeName: string;
      isBannerDismissed: boolean;
    };
    smartrrWidget?: {
      completed: boolean;
    };
  };
}

export enum SetupTabsEnum {
  SUBSCRIPTION,
  ONSITE,
}
