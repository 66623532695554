import { ApolloClient } from "@apollo/client";
import { paginatedRequestStreamShopifyGraphQL } from "@smartrr/shared/shopifyGraphQL/_utils";
import {
  querySellingPlanGroupProducts,
  querySellingPlanGroupVariants,
} from "@smartrr/shared/shopifyGraphQL/sellingPlans";
import { MAX_PAGE_SIZE } from "@smartrr/shared/utils/paginatedQuery";

export const loadProductsAndVariants = async (
  newProductCursor: string | null,
  newVariantCursor: string | null,
  selectedSellingPlanId: string,
  apolloClient: ApolloClient<object>
) => {
  const productIds: string[] = [];
  const variantIds: string[] = [];

  try {
    await paginatedRequestStreamShopifyGraphQL(
      (variantCursor: any) =>
        querySellingPlanGroupVariants(apolloClient, selectedSellingPlanId!, MAX_PAGE_SIZE, variantCursor),
      (res: any) => res.data.sellingPlanGroup?.productVariants,
      async (res: any) => {
        const variantNodes = res.data.sellingPlanGroup?.productVariants.edges.map(({ node }: any) => node) || [];
        for (const node of variantNodes) {
          variantIds.push(node.id);
        }
      }
    );

    await paginatedRequestStreamShopifyGraphQL(
      (productCursor: any) =>
        querySellingPlanGroupProducts(apolloClient, selectedSellingPlanId!, MAX_PAGE_SIZE, productCursor),
      (res: any) => res.data.sellingPlanGroup?.products,
      async (res: any) => {
        const productNodes = res.data.sellingPlanGroup!.products.edges.map(({ node }: any) => node) || [];
        for (const node of productNodes) {
          productIds.push(node.id);
        }
      }
    );
  } catch (error) {
    console.log(error);
  }

  return {
    productIds,
    variantIds,
  };
};
