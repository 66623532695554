import { IPurchasable } from "@smartrr/shared/entities/Purchasable";

// @TODO: #refactor – this should be revisited. either we should pass in a single IPurchasable
// or format a list, but we should pass in a while array of items every time just to select the first one
export const formatProductString = (purchasables: IPurchasable[]) => {
  const specialChars = /[!"#$%&'()*+,./:;<=>?@[\\\]^_`{|}~]/g;
  const firstPurchasable = purchasables[0];
  return firstPurchasable?.purchasableName
    .replaceAll(specialChars, "")
    .replaceAll(" ", "-")
    .replaceAll("--", "-");
};
