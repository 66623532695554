import { Req } from "@smartrr/shared/req";
import { getShopifyAsset } from "@smartrr/shared/shopifyRest/asset";
import { ISmartrrShopTheme, getShopifyThemes } from "@smartrr/shared/shopifyRest/theme";

import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

export async function getAsset(restClient: Req, themeId: number, assetId: string) {
  const res = await getShopifyAsset(themeId, assetId, restClient);
  if (res.type === "error" || res.body.asset.value === undefined) {
    const error = `Couldn't get asset ${assetId}`;
    throw new Error(error);
  }
  return res.body.asset.value;
}

export async function updateAsset(themeId: number, assetId: string, value: string) {
  return typedFrontendVendorApi.putReq("/theme/:themeId/asset", {
    params: {
      themeId: `${themeId}`,
    },
    reqBody: {
      key: assetId,
      value,
    },
  });
}

export async function updateCustomerPortalToken(themeId: number) {
  return typedFrontendVendorApi.putReq("/theme/:themeId/portal-token", {
    params: {
      themeId: `${themeId}`,
    },
  });
}

export const loadThemes = async (
  themes: ISmartrrShopTheme[],
  restClient: Req,
  toast: (content: string, error?: boolean | undefined) => void
) => {
  if (themes.length) {
    return;
  }

  const res = await getShopifyThemes(restClient);
  if (res.type === "success") {
    if (res.body.themes.length) {
      return [...res.body.themes].sort((a, b) => {
        if (a.role === "main") {
          return -1;
        } else if (+new Date(a.updated_at) > +new Date(b.updated_at)) {
          return -1;
        }
        return 1;
      });
    }
    toast("No themes found. Try to add theme first");
  } else {
    toast("Error getting themes. Try to reload the page");
  }
};
