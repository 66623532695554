import { createLazyFileRoute } from "@tanstack/react-router";

import { ErrorBoundary } from "@vendor-app/app/_sharedComponents/ErrorBoundary";
import { AdminSetupRoute } from "@vendor-app/app/AdminRoute/AdminSetupRoute";

export const Route = createLazyFileRoute("/admin/setup")({
  component: Index,
  errorComponent: ({ error, info }) => <ErrorBoundary error={error} info={info} />,
});

function Index() {
  return <AdminSetupRoute />;
}
