import type { ISellingPlanGroupCreate } from "@smartrr/shared/entities/SellingPlanGroup";
import {
  SellingPlanCategory,
  SellingPlanInterval,
  SellingPlanPricingPolicyAdjustmentType,
  SellingPlanRecurringDeliveryPolicyIntent,
  SellingPlanRecurringDeliveryPolicyPreAnchorBehavior,
} from "@smartrr/shared/shopifyGraphQL/api";
import config from "@vendor-app/config";

export const sellingPlanInput = {
  merchantCode: "Subscribe & Save 10%",
  name: "Subscribe & Save 10%",
  options: ["Subscribe & Save 10%"],
  appId: config.shopify.appId,
  description: "Subscribe & Save 10%",
  sellingPlansToCreate: [
    {
      name: "30 days",
      description: "Subscribe & Save 10%",
      options: ["30 days"],
      category: SellingPlanCategory.Subscription,
      pricingPolicies: [
        {
          fixed: {
            adjustmentType: SellingPlanPricingPolicyAdjustmentType.Percentage,
            adjustmentValue: {
              percentage: 10,
            },
          },
        },
      ],
      billingPolicy: {
        recurring: {
          anchors: [],
          interval: SellingPlanInterval.Day,
          intervalCount: 30,
          maxCycles: null,
          minCycles: null,
        },
      },
      deliveryPolicy: {
        recurring: {
          anchors: [],
          cutoff: null,
          intent: SellingPlanRecurringDeliveryPolicyIntent.FulfillmentBegin,
          interval: SellingPlanInterval.Day,
          intervalCount: 30,
          preAnchorBehavior: SellingPlanRecurringDeliveryPolicyPreAnchorBehavior.Asap,
        },
      },
    },
  ],
};

export const demoSellingPlanGroup: ISellingPlanGroupCreate = {
  adminLabel: "Subscribe & Save 10%",
  storefrontLabel: "Subscribe & Save 10%",
  optionLabel: "Subscribe & Save 10%",
  sellingPlans: [
    {
      cartCheckoutLabel: "30 days",
      displayLabel: "30 days",
      pricingPolicies: [
        {
          fixed: {
            adjustmentType: "PERCENTAGE",
            adjustmentValue: {
              percentage: 10,
            },
          },
        },
      ],
      billingPolicy: {
        recurring: {
          interval: "DAY",
          intervalCount: 30,
        },
      },
      deliveryPolicy: {
        recurring: {
          interval: "DAY",
          intervalCount: 30,
          preAnchorBehavior: "ASAP",
        },
      },
    },
  ],
};
