import { type ApolloClient, useApolloClient } from "@apollo/client";
import {
  Button,
  Icon,
  LegacyCard,
  LegacyStack,
  Link,
  Modal,
  Pagination,
  Text,
  Thumbnail,
  Tooltip,
} from "@shopify/polaris";
import { CircleTickMajor } from "@shopify/polaris-icons";
import { ISODateString } from "@smartrr/shared/entities/ISODateString";
import { useSmartrrFlags } from "@smartrr/shared/LaunchDarkly";
import { MediaContentType, ProductInput, ProductStatus } from "@smartrr/shared/shopifyGraphQL/api";
import { mutationShopifyProductCreate, queryGetLocations } from "@smartrr/shared/shopifyGraphQL/sellingPlans";
import { shopifyNumericIdFromGid } from "@smartrr/shared/utils/ensureShopifyGid";
import { MAX_PAGE_SIZE } from "@smartrr/shared/utils/paginatedQuery";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useToast } from "@smartrr/vendor-portal/src/app/_sharedComponents/Toast/ToastProvider";
import { BrowseProductsModal } from "@vendor-app/app/_sharedComponents/BrowseProductsModal";
import { updateSetup } from "@vendor-app/app/_state/actionCreators/organization";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { PaginationContainer } from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/libs";
import { bustSellingPlanGroupCache } from "@vendor-app/utils/sellingPlanGroupProductManagement";

import { ModalTitle, SetupIconContainer, SetupIconWrapper } from "../../../../../../libs/styles/index";
import { ISetupFieldsForUpdate } from "../../../../../../libs/types";
import {
  addProductsToGroup,
  addVariantsToGroup,
  removeProductsFromGroup,
  removeVariantsFromGroup,
} from "../../../../../../libs/utils";

interface ISubscriptionProductsCard {
  linkedProductIds: string[];
  linkedVariantIds: string[];
  isProductsPageLoading: boolean;
  isVariantsPageLoading: boolean;
  loadProductsAndVariantsFromShopify: (selectedSellingPlanId: string) => Promise<void>;
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
}

/**
 * This image lives in the 'Smartrr Snacks' Shop's Content Library. If we host
 * it locally to Smartrr, the image won't resolve properly in local development.
 */
const smartrrSnacksDemoCookieUrl =
  "https://cdn.shopify.com/s/files/1/0607/2257/7632/files/Demo_Cookie.png?v=1705697542";

const SubscriptionProductsCard = ({
  isProductsPageLoading,
  isVariantsPageLoading,
  linkedProductIds,
  linkedVariantIds,
  loadProductsAndVariantsFromShopify,
  setSelectedTab,
}: ISubscriptionProductsCard): JSX.Element => {
  const { addToast } = useToast();
  const apolloClient = useApolloClient();
  const dispatch = useSmartrrVendorDispatch();
  const { purchasables } = useSmartrrVendorSelector(state => state.purchasables);
  const { setup } = useSmartrrVendorSelector(state => state.vendorOrganizations);
  const { selectedSellingPlanId } = setup;
  const [demoModalOpen, setDemoModalOpen] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const { subscriptionProgramsImprovement: subscriptionProgramsImprovementFeatureFlagEnabled } =
    useSmartrrFlags();

  const [filteredPurchasablesSearchText, setFilteredPurchasablesSearchText] = useState<string>("");

  const [showProductOptionsModal, setShowProductOptionsModal] = useState(false);
  const [modalSelectedProductOrVariantIds, setModalSelectedProductOrVariantIds] = useState<string[]>([]);
  const [createDemoProductLoading, setCreateDemoProductLoading] = useState<boolean>(false);
  const [isAddingProducts, setIsAddingProducts] = useState<boolean>(false);

  useEffect(() => {
    if (setup.selectedSellingPlanId) {
      setModalSelectedProductOrVariantIds([...linkedProductIds, ...linkedVariantIds]);
    }
  }, [linkedVariantIds, linkedProductIds]);

  const onEditProducts = useCallback(
    async (ids: string[]) => {
      if (!selectedSellingPlanId) {
        addToast("Group does not have an id");
        return;
      }

      const toAdd = ids.filter(id => !linkedProductIds.includes(id));
      const toRemove = linkedProductIds.filter(id => !ids.includes(id));

      if (toAdd.length) {
        await addProductsToGroup(apolloClient, selectedSellingPlanId, toAdd);
      }

      if (toRemove.length) {
        await removeProductsFromGroup(apolloClient, selectedSellingPlanId, toRemove);
      }
    },
    [selectedSellingPlanId, linkedProductIds]
  );

  const onEditVariants = useCallback(
    async (ids: string[]) => {
      if (!selectedSellingPlanId) {
        addToast("Group does not have an id");
        return;
      }

      const toAdd = ids.filter(id => !linkedVariantIds.includes(id));
      const toRemove = linkedVariantIds.filter(id => !ids.includes(id));

      if (toAdd.length) {
        await addVariantsToGroup(apolloClient, selectedSellingPlanId, toAdd);
      }

      if (toRemove.length) {
        await removeVariantsFromGroup(apolloClient, selectedSellingPlanId, toRemove);
      }
    },
    [selectedSellingPlanId, linkedVariantIds]
  );

  const onEditProductsOrVariantsCb = useCallback(
    async (selectedProductOrVariantIds: string[]) => {
      setIsAddingProducts(true);
      try {
        const selectedProductIds = selectedProductOrVariantIds.filter(p => p.includes("Product/"));
        const selectedProductVariantIds = selectedProductOrVariantIds.filter(p => p.includes("ProductVariant/"));

        if (subscriptionProgramsImprovementFeatureFlagEnabled) {
          await setProductsInGroup(selectedSellingPlanId, {
            productIds: selectedProductIds,
            productVariantIds: selectedProductVariantIds,
          });
        } else {
          await onEditProducts(selectedProductIds);
          await onEditVariants(selectedProductVariantIds);
          void bustSellingPlanGroupCache();
        }

        handleUpdateSetup({
          subscriptionSetup: {
            ...setup?.subscriptionSetup,
            product: {
              date: new Date().toISOString(),
              completed: true,
              demo: false,
            },
          },
        });

        void loadProductsAndVariantsFromShopify(selectedSellingPlanId!);
        addToast("Products updated");
      } catch {
        addToast("Something went wrong updating products. Please try again or contact support.", true);
      } finally {
        setIsAddingProducts(false);
      }
    },
    [onEditProducts, onEditVariants, selectedSellingPlanId]
  );

  const handleUpdateSetup = (setupField: ISetupFieldsForUpdate) => {
    dispatch(updateSetup(setupField, undefined, true));
  };

  const handleCreatingDemoProduct = async () => {
    setCreateDemoProductLoading(true);
    try {
      const productDetails = await createDemoSellingPlanGroupProduct(apolloClient);

      if (subscriptionProgramsImprovementFeatureFlagEnabled) {
        await assignProductToGroup(selectedSellingPlanId, productDetails);
      } else {
        await addProductsToGroup(apolloClient, selectedSellingPlanId, [productDetails.productId]);
        await addVariantsToGroup(apolloClient, selectedSellingPlanId, [productDetails.productVariantId]);
        void bustSellingPlanGroupCache();
      }

      handleUpdateSetup({
        subscriptionSetup: {
          sellingPlan: { ...setup.subscriptionSetup.sellingPlan },
          product: {
            date: ISODateString.toString(new Date()),
            completed: true,
            demo: true,
          },
        },
      });

      addToast("Demo product created.");
    } catch {
      addToast("Something went wrong creating the demo product. Please try again or contact support.", true);
    } finally {
      setCreateDemoProductLoading(false);
    }
  };

  const onAddProductsAndVariantsClick = useCallback(async () => {
    if (selectedSellingPlanId) {
      setShowProductOptionsModal(true);
    } else {
      addToast("Create a subscription program first");
    }
  }, [selectedSellingPlanId, linkedProductIds, linkedVariantIds]);

  const purchasableOptions = useMemo(() => {
    const filteredAndSortedPurchasables = purchasables.filter(p => !!p.shopifyId && !p.isDraftOrArchived).sort();

    if (filteredPurchasablesSearchText) {
      return filteredAndSortedPurchasables.filter(p =>
        p.purchasableName.toLocaleLowerCase().includes(filteredPurchasablesSearchText.toLocaleLowerCase())
      );
    }

    return filteredAndSortedPurchasables;
  }, [purchasables, filteredPurchasablesSearchText]);

  const totalPages = Math.ceil(purchasableOptions.length / MAX_PAGE_SIZE);

  return (
    <React.Fragment>
      <LegacyCard title="2. Select subscription products">
        <LegacyCard.Section>
          <Text variant="bodyMd" as="p">
            Pick which products you&apos;d like to offer to as subscriptions to your customers. If you&apos;d like
            to start with a demo product, Smartrr can create a test product for demonstration purposes only.
            Alternatively, you can get started with a products already in your shop.
          </Text>{" "}
          &nbsp;
          <LegacyStack vertical>
            <LegacyStack>
              <Button
                loading={createDemoProductLoading}
                onClick={() => {
                  if (!selectedSellingPlanId) {
                    addToast("Create a subscription program first");
                    return;
                  }
                  setDemoModalOpen(true);
                }}
              >
                Use a demo product
              </Button>
              {!!setup.subscriptionSetup?.product?.demo && !!setup.subscriptionSetup?.product?.completed && (
                <SetupIconContainer>
                  <SetupIconWrapper>
                    <Icon source={CircleTickMajor} color="base" />
                  </SetupIconWrapper>
                  <Text
                    variant="bodyMd"
                    as="span"
                    color="subdued"
                  >{` You created a demo product on ${ISODateString.fromString(
                    setup.subscriptionSetup.product.date
                  ).toLocaleString()}`}</Text>
                </SetupIconContainer>
              )}
            </LegacyStack>
            {purchasables.length ? (
              <LegacyStack alignment="center">
                <Button
                  loading={isAddingProducts || isProductsPageLoading || isVariantsPageLoading}
                  primary
                  onClick={onAddProductsAndVariantsClick}
                  disabled={!purchasables.length}
                >
                  Select from existing products
                </Button>
                {!setup.subscriptionSetup?.product?.demo && !!setup.subscriptionSetup?.product?.completed && (
                  <SetupIconContainer>
                    <SetupIconWrapper>
                      <Icon source={CircleTickMajor} color="base" />
                    </SetupIconWrapper>
                    <Link url="/admin/configure/plans">Manage subscription programs</Link>
                  </SetupIconContainer>
                )}
              </LegacyStack>
            ) : (
              <Tooltip content="Create a product in your Shopify admin to be able to select an existing product.">
                <Button primary onClick={onAddProductsAndVariantsClick} disabled={!purchasables.length}>
                  Select from existing products
                </Button>
              </Tooltip>
            )}
          </LegacyStack>
        </LegacyCard.Section>
      </LegacyCard>

      <Modal
        open={demoModalOpen}
        title={<ModalTitle>Creating a demo product</ModalTitle>}
        onClose={() => setDemoModalOpen(false)}
        primaryAction={{
          content: "Confirm",
          onAction() {
            handleCreatingDemoProduct().then(() => {
              setDemoModalOpen(false);
              if (setup?.subscriptionSetup?.sellingPlan?.completed) {
                setSelectedTab(1);
              }
            });
          },
          loading: createDemoProductLoading,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: () => setDemoModalOpen(false),
          },
        ]}
      >
        <Modal.Section>
          Smartrr will create the below demo product in your Shopify store. You will be able to delete this
          product later directly in your Shopify admin.
        </Modal.Section>
        <Modal.Section>
          <LegacyCard sectioned>
            <LegacyStack spacing="tight">
              <Thumbnail size="medium" source={smartrrSnacksDemoCookieUrl} alt="chip" />
              <LegacyStack vertical>
                <Text variant="headingLg" as="p">
                  Smartrr Snack Cookie (Demo)
                </Text>
                <div>Chocolate Chip</div>
              </LegacyStack>
            </LegacyStack>
          </LegacyCard>
        </Modal.Section>
      </Modal>
      <BrowseProductsModal
        purchasableOptions={purchasableOptions.slice(
          pageNumber * MAX_PAGE_SIZE,
          pageNumber * MAX_PAGE_SIZE + MAX_PAGE_SIZE
        )}
        selectedProductsAndVariants={modalSelectedProductOrVariantIds}
        searchText={filteredPurchasablesSearchText}
        title="Selecting the existing product and variants"
        open={showProductOptionsModal}
        onClose={() => {
          setShowProductOptionsModal(false);
          setModalSelectedProductOrVariantIds([...linkedProductIds, ...linkedVariantIds]);
        }}
        primaryAction={useMemo(
          () => ({
            content: "Confirm Products/Variants",
            async onAction() {
              await onEditProductsOrVariantsCb(modalSelectedProductOrVariantIds);
              setShowProductOptionsModal(false);
              if (setup?.subscriptionSetup?.sellingPlan?.completed) {
                setSelectedTab(1);
              }
            },
            loading: isAddingProducts || isProductsPageLoading || isVariantsPageLoading,
            disabled: !modalSelectedProductOrVariantIds.length,
          }),
          [
            onEditProductsOrVariantsCb,
            isAddingProducts,
            isVariantsPageLoading,
            isProductsPageLoading,
            modalSelectedProductOrVariantIds,
          ]
        )}
        secondaryActions={[
          {
            content: "Cancel",
            onAction() {
              setShowProductOptionsModal(false);
              setModalSelectedProductOrVariantIds([...linkedProductIds, ...linkedVariantIds]);
            },
          },
        ]}
        footer={
          <PaginationContainer>
            <Pagination
              hasNext={totalPages > pageNumber + 1}
              hasPrevious={pageNumber != 0}
              label={`Showing ${pageNumber + 1} of ${totalPages || 1}`}
              onNext={() => setPageNumber(prev => prev + 1)}
              onPrevious={() => setPageNumber(prev => prev - 1)}
            />
          </PaginationContainer>
        }
        onSearchChange={text => {
          setFilteredPurchasablesSearchText(text);

          setPageNumber(0);
        }}
        setSelectedProductAndVariantIds={setModalSelectedProductOrVariantIds}
      />
    </React.Fragment>
  );
};

export default SubscriptionProductsCard;

interface ProductDetails {
  productId: string;
  productVariantId: string;
}

async function createDemoSellingPlanGroupProduct(apolloClient: ApolloClient<object>): Promise<ProductDetails> {
  const locationsResponse = await queryGetLocations(apolloClient);
  if (locationsResponse.type !== "success") {
    throw new Error("Failed to get locations from Shopify");
  }

  const productCreateResponse = await mutationShopifyProductCreate(
    {
      title: "Smartrr Snack Cookie (Demo)",
      descriptionHtml: "<span>Smartrr Snack Cookie (Demo)</span>",
      status: ProductStatus.Active,
      /**
       * This needs to use ProductVariantCreate instead
       * https://shopify.dev/docs/api/admin-graphql/2024-04/mutations/productVariantCreate
       */
      variants: [
        {
          price: "10",
          inventoryQuantities: [
            {
              availableQuantity: 100,
              locationId: locationsResponse.body.data.locations.edges[0].node.id,
            },
          ],
        },
      ],
    } as unknown as ProductInput,
    {
      alt: "Cookie",
      mediaContentType: MediaContentType.Image,
      originalSource: smartrrSnacksDemoCookieUrl,
    },
    apolloClient
  );

  const createdProduct = productCreateResponse.body.data?.productCreate?.product;
  if (!createdProduct) {
    throw new Error("Unexpected response body from Shopify on demo product create");
  }

  return {
    productId: createdProduct.id,
    productVariantId: createdProduct.variants.edges[0].node.id,
  };
}

/**
 * Add the single product and variant to the Selling Plan Group, leaving
 * any existing assignments as-is.
 */
async function assignProductToGroup(
  shopifySellingPlanGroupId: string,
  productDetails: ProductDetails
): Promise<void> {
  const existingGroup = await typedFrontendVendorApi.getReq(`/selling-plan-groups/:id`, {
    params: { id: shopifyNumericIdFromGid(shopifySellingPlanGroupId) },
  });

  if (existingGroup.type !== "success") {
    throw new Error("Failed to lookup demo Subscription Program");
  }

  const result = await typedFrontendVendorApi.putReq(`/selling-plan-groups/:id`, {
    params: {
      id: existingGroup.body.id,
    },
    reqBody: {
      productIds: [
        ...new Set([...existingGroup.body.productIds, shopifyNumericIdFromGid(productDetails.productId)]),
      ],
      productVariantIds: [
        ...new Set([
          ...existingGroup.body.productVariantIds,
          shopifyNumericIdFromGid(productDetails.productVariantId),
        ]),
      ],
    },
  });

  if (result.type !== "success") {
    throw new Error("Failed to assign demo Product to demo Subscription Program");
  }
}

/**
 * Set the products on the Selling Plan Group to exactly what we say.
 */
async function setProductsInGroup(
  shopifySellingPlanGroupId: string,
  { productIds, productVariantIds }: SellingPlanGroupProducts
): Promise<void> {
  const existingGroup = await typedFrontendVendorApi.getReq(`/selling-plan-groups/:id`, {
    params: { id: shopifyNumericIdFromGid(shopifySellingPlanGroupId) },
  });

  if (existingGroup.type !== "success") {
    throw new Error("Failed to lookup demo Subscription Program");
  }

  const result = await typedFrontendVendorApi.putReq(`/selling-plan-groups/:id`, {
    params: {
      id: existingGroup.body.id,
    },
    reqBody: {
      productIds: productIds.map(p => shopifyNumericIdFromGid(p)),
      productVariantIds: productVariantIds.map(v => shopifyNumericIdFromGid(v)),
    },
  });

  if (result.type !== "success") {
    throw new Error("Failed to set Products in demo Subscription Program");
  }
}

interface SellingPlanGroupProducts {
  productIds: string[];
  productVariantIds: string[];
}
